import Bowser from 'bowser';
import _ from 'lodash';
import { mapGetters } from 'vuex';

import flagCountry from 'country-json/src/country-by-abbreviation.json';
import callingCode from 'country-json/src/country-by-calling-code.json';

import apiAuth from '@/helpers/apis/serviceAuth';
import authTypes from '@/store/types/auth';
import eventTypes from '@/store/types/event';
import globalTypes from '@/store/types/global';
import pollTypes from '@/store/types/poll';
import reservationTypes from '@/store/types/reservation';
import sectionTypes from '@/store/types/section';
import whiteLabelTypes from '@/store/types/whiteLabel';
import LCrypt from 'lcrypt';

import { v4 as uuidv4 } from 'uuid';

import Vue2Gtm from '@gtm-support/vue2-gtm';
import Vue from 'vue';
import router from '@/router';

const lcrypt = new LCrypt(process.env.VUE_APP_TOKEN);

export default {
  computed: {
    ...mapGetters({
      // ================ Datos del restaurante====================================
      vendors: [whiteLabelTypes.getters.vendor],
      getTimeAtentionWl: [reservationTypes.getters.times],
      reservations: [reservationTypes.getters.reservations],
      allEvents: [eventTypes.getters.allEvents],
      eventMinPeople: [eventTypes.getters.eventMinPeople],
      zones: [sectionTypes.getters.zones],
      schedules: [reservationTypes.getters.schedules],
      blocked: [reservationTypes.getters.blocked],
      availabilityCalendar: [reservationTypes.getters.availabilityCalendar],
      // ================ Proceso de reserva ======================================
      selectedPeople: reservationTypes.getters.selectedPeople,
      selectedDate: reservationTypes.getters.selectedDate,
      selectedDateTemporal: reservationTypes.getters.selectedDateTemporal,
      selectedHour: reservationTypes.getters.selectedHour,
      selectedHourAction: reservationTypes.getters.selectedHourAction,
      selectedZone: reservationTypes.getters.selectedZone,
      selectedTable: reservationTypes.getters.selectedTable,
      section: whiteLabelTypes.getters.section, // Indica en que parte del proceso te encuentras (people, date, hour)
      loadHour: whiteLabelTypes.getters.loadHour,
      reservationDayUser: reservationTypes.getters.reservationDayUser,
      isFree: whiteLabelTypes.getters.isFree,
      sendReservation: reservationTypes.getters.sendReservation, // Indica si la reserva se envió al checkout
      reservationsUser: reservationTypes.getters.reservationsUser,
      reservationsUserLink: reservationTypes.getters.reservationsUserLink,
      reservationEvent: eventTypes.getters.reservationEvent, // Indica si la reserva es un evento
      dataReservationEdit: reservationTypes.getters.dataReservationEdit,
      editReservation: reservationTypes.getters.editReservation,
      loginProcessReservation: whiteLabelTypes.getters.loginProcessReservation, // Indica si tiene q hacer login despues de realizar la preReservation
      topBarSize: whiteLabelTypes.getters.topBarSize, // Tamaño del topbar para posicionar el boton de idioma
      // ================ Disponibilidad restaurante ==============================
      avalibilityHour: whiteLabelTypes.getters.avalibilityHour,
      // ================ Disponibilidad de restaurantes del grupo ================
      avalabilityGroup: whiteLabelTypes.getters.avalabilityGroup,
      isAvalability: whiteLabelTypes.getters.isAvalability,
      // Usuarios
      user: authTypes.getters.user,
      logged: authTypes.getters.logged,
      invite: whiteLabelTypes.getters.invite,
      vendorId: [whiteLabelTypes.getters.vendorId],
      hourGlobal: [whiteLabelTypes.getters.hourGlobal],
      // ================ Modals del whitelabel ===================================
      showModalLogin: [whiteLabelTypes.getters.showModalLogin],
      // ================ Reservas              ===================================
      reservationsList: reservationTypes.getters.reservationsList,
      listReservationTypes: reservationTypes.getters.listReservationTypes,
      selectedAdult: reservationTypes.getters.selectedAdult,
      selectedBoy: reservationTypes.getters.selectedBoy,
      selectedBaby: reservationTypes.getters.selectedBaby,
      selectedTypeReservation: reservationTypes.getters.selectedTypeReservation,
      inviteId: [authTypes.getters.inviteId],
      getUserId: [authTypes.getters.userId],
      getPoll: [pollTypes.getters.poll],
      pollInformation: [pollTypes.getters.pollInformation],
      selectedExperience: [whiteLabelTypes.getters.selectedExperience],
      send705: [whiteLabelTypes.getters.send705],
      loader: [whiteLabelTypes.getters.loader],
      disabledOnLog: [whiteLabelTypes.getters.disabledOnLog],
      versionApp: [globalTypes.getters.versionApp],
      languageApp: [globalTypes.getters.languageApp],
      embedSystem: [globalTypes.getters.embedSystem]
    }),
    deviceId () {
      let deviceId = window.localStorage.getItem('deviceId');
      if (deviceId) {
        return deviceId;
      } else {
        const key = uuidv4();
        window.localStorage.setItem('deviceId', key);
        return key;
      }
    },
    vendor () {
      return this.vendors.vendor;
    },
    design () {
      return this.vendors.desing;
    },
    params () {
      return this.vendors.params;
    },
    designEvent () {
      return this.vendors.designEvent;
    },
    vendorTimezone () {
      let tz = 'America/Bogota';
      if ((this.vendor || {}).timezone) {
        tz = this.vendor.timezone;
      }
      return tz;
    },
    idUser () {
      return this.getUserId;
    },
    poll () {
      return this.getPoll;
    },
    getStateMobile () {
      return this.$store.state.isMobile;
    },
    isShowRequestReservation () {
      if (this.isFree && this.params.isActiveRequestReservation === 1) {
        return true;
      }
      return false;
    }
  },
  methods: {
    ...mapGetters({
      Blocked: [reservationTypes.getters.blocked]
    }),
    oscurecerColor (hex, factor, opacidad = 0.6) {
      factor = Math.min(1, Math.max(0, factor));
      if (hex !== null) {
        // Si el color es blanco, cambiar hacia un gris más oscuro
        if (hex.toLowerCase() === '#fff' || hex.toLowerCase() === '#ffffff') {
          const nuevoGris = Math.round(255 * (1 - factor));
          return `rgba(${nuevoGris}, ${nuevoGris}, ${nuevoGris}, ${opacidad})`;
        }
        // Si el color es negro, cambiar hacia un gris
        if (hex.toLowerCase() === '#000' || hex.toLowerCase() === '#000000') {
          const nuevoGris = Math.round(factor * 255);
          return `rgba(${nuevoGris}, ${nuevoGris}, ${nuevoGris}, ${opacidad})`;
        }
        // Convertir el color hexadecimal a RGB y oscurecer
        let r = parseInt(hex.substring(1, 3), 16);
        let g = parseInt(hex.substring(3, 5), 16);
        let b = parseInt(hex.substring(5, 7), 16);
        r = Math.round(r * (1 - factor));
        g = Math.round(g * (1 - factor));
        b = Math.round(b * (1 - factor));
        return `rgba(${r}, ${g}, ${b}, ${opacidad})`;
      } else {
        return null;
      }
    },
    colorWithOpacity (edit = false) {
      const opacity = 0.3;
      let colorObject, colorWithOpacity;
      if (edit === true) {
        colorWithOpacity = this.design.bgColorCheckout;
      } else {
        colorObject = this.hexToRgb(this.design.color1);
        colorWithOpacity = `rgba(${colorObject.r}, ${colorObject.g}, ${colorObject.b}, ${opacity})`;
      }
      return colorWithOpacity;
    },
    colorWithTransparency (hex, transparency) {
      let colorObject, colorWithOpacity;
      colorObject = this.hexToRgb(hex);
      colorWithOpacity = `rgba(${colorObject.r}, ${colorObject.g}, ${colorObject.b}, ${transparency})`;
      return colorWithOpacity;
    },
    hexToRgb (hex) {
      // Verifica si el color hexadecimal está en el formato largo (RRGGBB)
      const longHexRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
      // Verifica si el color hexadecimal está en el formato largo (RRGGBBAA)
      const mediumHexRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
      // Verifica si el color hexadecimal está en el formato corto (RGB)
      const minHexRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      // Verifica si el color hexadecimal está en el formato corto (RGBA)
      const shortHexRegex = /^#?([a-f\d])([a-f\d])([a-f\d])([a-f\d])$/i;
      // Intenta hacer coincidir el color hexadecimal con los formatos largos y cortos
      const longHexMatch = longHexRegex.exec(hex);
      const mediumHexMatch = mediumHexRegex.exec(hex);
      const shortHexMatch = shortHexRegex.exec(hex);
      const minHexMatch = minHexRegex.exec(hex);
      if (longHexMatch) {
        // Si coincide con el formato largo, extrae los componentes RGB y la opacidad
        return {
          r: parseInt(longHexMatch[1], 16),
          g: parseInt(longHexMatch[2], 16),
          b: parseInt(longHexMatch[3], 16)
        };
      } else if (shortHexMatch) {
        // Si coincide con el formato corto, expande los componentes RGB y la opacidad
        return {
          r: parseInt(shortHexMatch[1] + shortHexMatch[1], 16),
          g: parseInt(shortHexMatch[2] + shortHexMatch[2], 16),
          b: parseInt(shortHexMatch[3] + shortHexMatch[3], 16)
        };
      } else if (mediumHexMatch) {
        // Si coincide con el formato corto, expande los componentes RGB y la opacidad
        return {
          r: parseInt(mediumHexMatch[1] + mediumHexMatch[1], 16),
          g: parseInt(mediumHexMatch[2] + mediumHexMatch[2], 16),
          b: parseInt(mediumHexMatch[3] + mediumHexMatch[3], 16)
        };
      } else if (minHexMatch) {
        // Si coincide con el formato corto, expande los componentes RGB y la opacidad
        return {
          r: parseInt(minHexMatch[1] + minHexMatch[1], 16),
          g: parseInt(minHexMatch[2] + minHexMatch[2], 16),
          b: parseInt(minHexMatch[3] + minHexMatch[3], 16)
        };
      } else {
        // Si no coincide con ninguno de los formatos, devuelve null
        return null;
      }
    },
    whatsIsTimezone () {
      const resolvedOptions = Intl.DateTimeFormat().resolvedOptions();
      return resolvedOptions.timeZone;
    },
    getVersionCurrent (info) {
      return new Promise((resolve, reject) => {
        apiAuth.post(`version`, info).then(({ data }) => {
          resolve(data);
        }).catch(err => {
          reject(err);
        });
      });
    },
    validateBtnLanguage () {
      if (location.pathname.includes('prepago')) {
        return false;
      }
      return !(location.pathname.includes('poll') === true || location.pathname.includes('account'));
    },
    showSchedules (scheType, date, field) {
      if (date > 0) {
        let f = new Date(parseInt(date));
        let dayOfWeek = this.$moment(f).day();
        let info = _.find(this.schedules[scheType], { dayOfWeek: dayOfWeek });
        if (info[field]) {
          if (info[field] != null) {
            return info[field];
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      } else {
        let dayOfWeek = this.$moment().day();
        let info = _.find(this.schedules[scheType], { dayOfWeek: dayOfWeek });
        if (info[field]) {
          if (info[field] != null) {
            return info[field];
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      }
    },
    monthConvert (month) {
      if (month === 1) {
        return 0;
      } else {
        return month - 1;
      }
    },
    encrypt (value) {
      return lcrypt.encode(value);
    },
    decryptMx (value) {
      return lcrypt.decode(value);
    },
    /**
     * [setSelectedDate description]
     * @param {[type]} date        [description]
     * @param {[type]} dateInitial [description]
     * @param {[type]} events      [description]
     */
    setSelectedDates (date, dateInitial, events) {
      let response = {};
      if (dateInitial && date) {
        let now = this.$moment(dateInitial).format('YYYY-MM-DD');
        let sel = this.$moment(date).format('YYYY-MM-DD');
        let bol = events[date];
        if (sel >= now) {
          this.$store.commit(reservationTypes.mutations.setSelectedDate, false);
          this.$store.commit(reservationTypes.mutations.setSelectedDate, date);
          response.evento = bol === undefined ? false : bol;
          response.date = date;
        }
      }
      return response;
    },
    subDomain () {
      if (this.groupUrlVendor != null) {
        return this.groupUrlVendor;
      } else {
        let subdomain = window.location.hostname.split('.')[0];
        if (subdomain === 'www') {
          subdomain = window.location.hostname.split('.')[1];
        }
        return (
          subdomain === 'localhost' ||
          subdomain === 'developers' ||
          subdomain === 'select' ||
          subdomain === '127' ||
          subdomain === 'dev' ||
          subdomain === '192'
        ) ? 'prueba' : subdomain;
      }
    },
    initReservation () {
      let data = this.$route.query;
      let isQuery = Object.keys(data).length;
      if (isQuery >= 1) {
        if (data.selectedDate) {
          this.$store.commit(
            reservationTypes.mutations.setSelectedDate,
            parseInt(data.selectedDate)
          );
        }
        if (data.selectedHour) {
          this.$store.commit(
            reservationTypes.mutations.setSelectedHour,
            parseInt(data.selectedHour)
          );
        }
      }
    },
    /**
     * [listCountry description]
     * @return {[type]} [description]
     */
    listCountry () {
      let data = [];
      let ind = null;
      if (this.user.indicativo != null && typeof this.user.indicativo !== 'number') {
        ind = this.user.indicativo.replace('+', '');
      } else {
        ind = this.user.indicativo;
      }
      _.forEach(callingCode, function (item) {
        if (ind != null && ind === item.calling_code && item.country !== 'Bouvet Island') {
          data.push({
            value: item.calling_code,
            name: item.country,
            label: item.country + ' ( +' + item.calling_code + ' )',
            selected: true
          });
        } else if (item.calling_code != null && item.country !== 'Bouvet Island') {
          data.push({
            value: item.calling_code,
            name: item.country,
            label: item.country + ' ( +' + item.calling_code + ' )'
          });
        }
      });
      return data;
    },
    validateTelephone (indicative) {
      let validate = '';
      switch (indicative) {
        case 57:
          validate = 'required|min:10|max:10|numeric';
          break;
        case 507:
          validate = 'required|min:7|max:8|numeric';
          break;
        default:
          validate = 'required|min:7|max:11|numeric';
          break;
      }
      return validate;
    },
    changedPais (countrys, indicative) {
      let response = {};
      let data = _(countrys)
        .filter(country => {
          if (country.value === indicative) {
            return country;
          }
        })
        .value();
      if (data.length > 0) {
        let aux2 = null;
        let aux3 = data[0].label;
        response.option = data[0];
        response.country = data[0].label;
        response.indicative = data[0].value;
        let ind = data[0].value;
        _.forEach(flagCountry, function (item) {
          let data2 = item.country + ' ( +' + ind + ' )';
          if (data2 === aux3) {
            aux2 = item.abbreviation.toLowerCase();
          }
        });
        response.flag = aux2 != null ? 'https://api.precompro.com/banderas/' + aux2 + '.png' : null;
        return response;
      }
    },
    /**
     * Function que retorna los datos del navegador y OS
     * @return {object}  objeto json con los datos del navegar browser, os, plataforma, engine
    */
    userAgentBrowser () {
      return Bowser.getParser(window.navigator.userAgent);
    },
    chargueScript (url, async = false) {
      if (!document.querySelector(`script[src='${url}']`)) {
        const recaptchaScript = document.createElement('script');
        recaptchaScript.src = url;
        if (async) {
          recaptchaScript.async = true;
        }
        document.head.appendChild(recaptchaScript);
      }
    },
    chargueAmp (url) {
      if (!document.querySelector(`script[src='${url}']`)) {
        const script = document.createElement('script');
        script.src = url;
        script.setAttribute('async', 'async');
        script.setAttribute('custom-element', 'amp-analytics');
        document.head.appendChild(script);
      }
    },
    chargueAmpJson (url) {
      if (!document.querySelector(`amp-analytics script[src='${url}']`)) {
        const info = document.createElement('amp-analytics');
        info.setAttribute('type', 'gtag');
        info.setAttribute('data-credentials', 'include');

        const data = document.createElement('script');
        data.setAttribute('type', 'application/json');
        data.setAttribute('src', url);

        info.appendChild(data);
        document.head.appendChild(info);
      }
    },
    loadScriptDefault (integrationDefaults) {
      if (integrationDefaults.length > 0) {
        integrationDefaults.map(item => {
          if (item.type === 'facebookPixel') {
            this.chargueScript(item.login);
          }
          if (item.type === 'googlePixel') {
            if (item.merchant !== null && item.merchant !== '') {
              this.chargueScript('https://www.googletagmanager.com/gtag/js?id=' + item.merchant);
            }
            if (item.account !== null && parseInt(item.account) === 1) {
              this.chargueAmp('https://cdn.ampproject.org/v0/amp-analytics-0.1.js');
              this.chargueAmpJson(item.amp);
            }
            this.chargueScript(item.login);
          }
        });
      }
    },
    loadScript (integrations) {
      if (integrations.length > 0) {
        integrations.map(item => {
          if (item.type === 'facebookPixel') {
            this.chargueScript(item.login);
            setTimeout(() => {
              if (item.faceEvents) {
                const eventItem = item.faceEvents.find((i) => i.url === this.$route.path);
                if (eventItem) {
                  if (eventItem.active === 1) {
                    if (eventItem.paramRequired === 1) {
                      // eslint-disable-next-line no-undef
                      fbq(eventItem.type, eventItem.code, JSON.parse(eventItem.params));
                    } else {
                      // eslint-disable-next-line no-undef
                      fbq(eventItem.type, eventItem.code);
                    }
                  }
                }
              }
            }, 800);
          }
          if (item.type === 'googlePixel') {
            if (item.merchant !== null && item.merchant !== '') {
              const noscript = document.createElement('noscript');
              noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${item.merchant}"
              height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
              document.body.insertBefore(noscript, document.body.firstChild);
            }
            if (item.account !== null && parseInt(item.account) === 1) {
              this.chargueAmp('https://cdn.ampproject.org/v0/amp-analytics-0.1.js');
              this.chargueAmpJson(item.amp);
            }
            const script = document.createElement('script');
            script.src = item.login;
            script.async = true;
            document.head.appendChild(script);
          }
        });
      }
    },
    dateNowInitial () {
      return this.$moment().format('YYYY/MM/DD');
    },
    /**
     * Inicializa mensaje del bloque people en el proceso de reserva
     * @param  {int}    minimumChargedPeople   [Numero inicial de personas]
     * @param  {object} params                 [Objeto params del establecimiento]
     * @return {void}                          [Setea el valor del estado isFree]
     */
    messageAnticipe (minimumChargedPeople, params) {
      if (minimumChargedPeople < params.minimumChargedPeople) {
        this.$store.commit(whiteLabelTypes.mutations.setIsFree, true);
      } else {
        if (params.purchaseCost <= 0) {
          this.$store.commit(whiteLabelTypes.mutations.setIsFree, true);
        } else {
          if (params.minimumChargedPeople) {
            if (minimumChargedPeople >= params.minimumChargedPeople) {
              this.$store.commit(whiteLabelTypes.mutations.setIsFree, false);
            } else {
              this.$store.commit(whiteLabelTypes.mutations.setIsFree, true);
            }
          } else {
            this.$store.commit(whiteLabelTypes.mutations.setIsFree, true);
          }
        }
      }
    },
    limitedPeople (params) {
      if (params.priorityMaxReservation === 'max') {
        if (
          params.maxReservationSize === null ||
          params.maxReservationSize === ''
        ) {
          return 20;
        }
        return this.params.maxReservationSize;
      } else {
        return 20; // se debe calcular el maximo de combinaciones
      }
    },
    /**
     * Valida si después del login de un usuario ya están los datos
     * precargados de una reserva para ejecutar la petición de crear
     * la pre-reserva y redireccionarlo al checkout
     * @return {void}
    */
    validateSendReservation () {
      let embed = 0;
      const routeParams = this.$route.query;
      if (Object.keys(routeParams).length > 0) {
        if (routeParams.embed) {
          embed = 1;
        }
      }
      if (this.sendReservation) {
        if (this.user.id || this.user.userId) {
          this.reservationsUser.name = this.user.displayName;
          this.reservationsUser.displayName = this.user.displayName;
          this.reservationsUser.email = this.user.email;
          if (this.user.phone) {
            this.reservationsUser.phone = this.user.phone;
          } else {
            this.reservationsUser.phone = null;
          }
          if (this.user.identityDocument) {
            this.reservationsUser.documentType = this.user.documentType;
            this.reservationsUser.identityDocument = this.user.identityDocument;
          }
          this.reservationsUser.userId = this.user.id || this.user.userId;
        } else {
          delete this.reservationsUser.name;
          delete this.reservationsUser.email;
          delete this.reservationsUser.userId;
          this.$store.commit(reservationTypes.mutations.setSendReservation, false);
        }
        this.reservationsUser.celebrationId = null;
        this.reservationsUser.celebrationComment = null;
        window.localStorage.setItem(
          '_reservation',
          btoa(JSON.stringify(this.reservationsUser))
        );
        this.$store.commit(reservationTypes.mutations.setReservationsUser);
        let data = this.reservationsUser;
        if (this.reservationEvent) {
          this.$store
            .dispatch({ type: 'event:preReservationEvent', data })
            .then(response => {
              if (parseInt(response.code) === 705) {
                this.$store.commit(
                  reservationTypes.mutations.setSendReservation,
                  false
                );
                this.$store.commit(
                  whiteLabelTypes.mutations.setSend705,
                  true
                );
                this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
              } else {
                this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
                if (Array.isArray(response.vendorAvailability)) {
                  window.localStorage.setItem(
                    '_reservation_time',
                    btoa(response.vendorAvailability[0].created_at)
                  );
                } else {
                  window.localStorage.setItem(
                    '_reservation_time',
                    btoa(response.vendorAvailability.created_at)
                  );
                }
                this.$store.commit(
                  whiteLabelTypes.mutations.setShowModalLogin,
                  false
                );
                let dataR = JSON.parse(atob(window.localStorage.getItem('_reservation')));
                if (dataR.futureReservationsId && dataR.vendorAvailability) {
                  if (window.localStorage.getItem('_token')) {
                    if (embed) {
                      window.location.href = '/checkout?embed=true';
                    } else {
                      window.location.href = '/checkout';
                    }
                  } else {
                    this.$store.commit(
                      whiteLabelTypes.mutations.setLoginProcessReservation,
                      !this.loginProcessRservation
                    );
                    this.$store.commit(
                      whiteLabelTypes.mutations.setShowModalLogin,
                      !this.showModalLogin
                    );
                  }
                }
              }
            })
            .catch(() => {
              this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
              this.$store.commit(
                reservationTypes.mutations.setSendReservation,
                false
              );
            });
        } else {
          this.$store.dispatch({ type: 'reservation:preReservation', data }).then(response => {
            if (parseInt(response.code) === 705) {
              this.$store.commit(
                reservationTypes.mutations.setSendReservation,
                false
              );
              this.$store.commit(
                whiteLabelTypes.mutations.setSend705,
                true
              );
              this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
            } else {
              this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
              if (Array.isArray(response.vendorAvailability)) {
                window.localStorage.setItem(
                  '_reservation_time',
                  btoa(response.vendorAvailability[0].created_at)
                );
              } else {
                window.localStorage.setItem(
                  '_reservation_time',
                  btoa(response.vendorAvailability.created_at)
                );
              }
              this.$store.commit(
                whiteLabelTypes.mutations.setShowModalLogin,
                false
              );
              let dataR = JSON.parse(atob(window.localStorage.getItem('_reservation')));
              if (dataR.futureReservationsId && dataR.vendorAvailability) {
                if (window.localStorage.getItem('_token')) {
                  if (embed) {
                    window.location.href = '/checkout?embed=true';
                  } else {
                    window.location.href = '/checkout';
                  }
                } else {
                  this.$store.commit(
                    whiteLabelTypes.mutations.setLoginProcessReservation,
                    !this.loginProcessRservation
                  );
                  this.$store.commit(
                    whiteLabelTypes.mutations.setShowModalLogin,
                    !this.showModalLogin
                  );
                }
              }
            }
          }).catch((e) => {
            this.$store.commit(whiteLabelTypes.mutations.setDisabledOnLog, false);
            this.$store.commit(reservationTypes.mutations.setSelectedHour, null);
            this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
            this.$store.commit(
              reservationTypes.mutations.setSendReservation,
              false
            );
            this.$buefy.snackbar.open({
              duration: 5000,
              message:
                  "<i class='fa fa-exclamation-circle alerticon'></i>La hora seleccionada ya ha sido ocupada",
              type: 'is-info',
              position: 'is-top-right',
              actionText: 'X',
              queue: false
            });
            let errorTimeOut = false;
            if (e.type === 'timeOut') {
              errorTimeOut = true;
            }
            this.$emit('error', {
              errorTimeOut
            });
            this.$store.commit(whiteLabelTypes.mutations.setLoadHour, false);
          });
        }
      } else {
        if (this.loginProcessReservation) {
          this.reservationsUser.name = this.user.displayName;
          this.reservationsUser.displayName = this.user.displayName;
          this.reservationsUser.email = this.user.email;
          if (this.user.phone) {
            this.reservationsUser.phone = this.user.phone;
          } else {
            this.reservationsUser.phone = null;
          }
          this.reservationsUser.userId = this.user.id || this.user.userId;
          window.localStorage.setItem(
            '_reservation',
            btoa(JSON.stringify(this.reservationsUser))
          );
          this.$store.commit(reservationTypes.mutations.setReservationsUser);
          let dataR = JSON.parse(atob(window.localStorage.getItem('_reservation')));
          let dat = {
            ...this.user,
            id: dataR.futureReservationsId,
            userId: this.user.id || this.user.userId
          };

          this.$store
            .dispatch({ type: 'reservation:updatePreReservation', dat })
            .then(() => {
              this.$store.commit(whiteLabelTypes.mutations.setShowModalLogin, false);
              if (embed) {
                window.location.href = '/checkout?embed=true';
              } else {
                window.location.href = '/checkout';
              }
            })
            .catch(() => {
              location.reload();
              this.$store.commit(whiteLabelTypes.mutations.setShowModalLogin, false);
            });
        } else {
          this.$store.commit(whiteLabelTypes.mutations.setShowModalLogin, false);
        }
      }
    },
    getInApp (navigator) {
      // eslint-disable-next-line
      // 'Android.*(wv|\.0\.0\.0)',
      // eslint-disable-next-line
      // '(iPhone|iPod|iPad)(?!.*Safari\/)',
      const rules = [
        'WebView',
        'Instagram',
        'Facebook'
      ];
      const regex = new RegExp(`(${rules.join('|')})`, 'ig');
      if ((navigator.match(regex))) {
        return false;
      } else {
        return true;
      }
    },
    generalChecksSvg (check, type) {
      const classCheckbox = `general-checks-svg-color__${type}`;
      var x = document.getElementsByClassName('general-checks-svg-color');
      var i;
      for (i = 0; i < x.length; i++) {
        var y = x[i].getElementsByClassName(`${classCheckbox} b-checkbox checkbox`);
        var j;
        for (j = 0; j < y.length; j++) {
          var c = y[j].getElementsByTagName('input');
          for (var k = 0; k < c.length; k++) {
            if (c[k].type === 'checkbox') {
              if (c[k].checked) {
                var ch = y[j].getElementsByClassName('check');
                for (var l = 0; l < ch.length; l++) {
                  var urllow =
                    "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath fill='%23" +
                    this.design.colorCheckboxTerms +
                    "' d='M 0.04038059,0.6267767 0.14644661,0.52071068 0.42928932,0.80355339 0.3232233,0.90961941 z M 0.21715729,0.80355339 0.85355339,0.16715729 0.95961941,0.2732233 0.3232233,0.90961941 z'%3E%3C/path%3E%3C/svg%3E";
                  if (check) {
                    ch[l].style.background = 'url("' + urllow + '")';
                  } else {
                    ch[l].style.background = 'none';
                  }
                }
              } else {
                ch = y[j].getElementsByClassName('check');
                for (l = 0; l < ch.length; l++) {
                  ch[l].style.background = 'none';
                }
              }
            }
          }
        }
      }
    },
    loadScriptPersonalize (vendorId) {
      let sinMenteId = [495, 99, 130, 561, 678];
      if (sinMenteId.includes(vendorId)) {
        Vue.use(Vue2Gtm, {
          id: 'GTM-KL55H9BB', // ID del contenedor
          customScriptSource: 'https://load.g.byads.co/tools-precompro.js', // Script personalizado
          vueRouter: router, // Sincronización con Vue Router
          enabled: true, // Activa el GTM
          debug: true, // Logs de depuración
          loadScript: true // Permite que el script se cargue automáticamente
        });
      }
    },
    isAndresDc () {
      return this.vendor.id === 784 || this.vendor.id === 786 || this.vendor.id === 966;
    }
  }
};
